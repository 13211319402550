<template>
	<v-app>
		<main class="fullh">
			<v-container fluid fill-height class="loginOverlay">
				<v-layout flex align-center justify-center>
					<v-flex xs12 sm4>
						<div style="text-align: center;" class="mb-2">
							<img src="../assets/logo-sc-blanco.svg" />
						</div>
						<v-card elevation="0" shaped>
							<v-card-text class="pt-4">
								<div>
									<v-form v-model="valid" ref="form">
										<v-text-field
											:label="$t('global.password')"
											v-model="password"
											min="8"
											type="password"
											:rules="passwordRules"
											counter
											required
											outlined
											autocomplete="new-password"
											dense
										></v-text-field>
										<v-text-field
											:label="
												$t('recover.repeatPassword')
											"
											v-model="repeat_password"
											min="8"
											type="password"
											:rules="passwordRepeatRules"
											counter
											required
											outlined
											autocomplete="new-password"
											dense
										></v-text-field>
										<v-layout
											justify-space-between
											class="pt-3"
										>
											<v-spacer></v-spacer>
											<v-btn
												@click="submit"
												:class="{
													'white--text': valid,
												}"
												tile
												:disabled="!valid || disabled"
												color="deep-purple accent-4"
												>{{
													$t(
														"recover.btnUpdatePassword"
													)
												}}</v-btn
											>
										</v-layout>
									</v-form>
								</div>
							</v-card-text>
						</v-card>
					</v-flex>
				</v-layout>
			</v-container>
		</main>
	</v-app>
</template>

<script>
import { mapMutations } from "vuex";

export default {
	data() {
		return {
			valid: false,
			password: "",
			repeat_password: "",
			passwordRules: [
				(v) => !!v || this.$t("global.rules.passwordRequired"),
			],
			passwordRepeatRules: [
				(v) => !!v || this.$t("global.rules.passwordRequired"),
				(v) =>
					v === this.password ||
					this.$t("global.rules.passwordNoMatch"),
			],
			email: "",
			emailRules: [
				(v) => !!v || this.$t("global.rules.emailRequired"),
				(v) =>
					/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
					this.$t("global.rules.emailValid"),
			],
			loading: false,
			disabled: false,
			form: {
				token: this.$route.query.token,
				password: "",
				passwordRepeat: "",
			},
		};
	},
	methods: {
		...mapMutations("ui", {
			openSnackbar: "openSnackbar",
		}),
		submit() {
			this.form.password = this.password;
			this.form.passwordRepeat = this.repeat_password;
			this.disabled = true;

			console.log(this.form);

			if (this.$refs.form.validate()) {
				this.$axios
					.post("/auth/update-password", this.form)
					.then(() => {
						this.$router.push({ name: "Login" });
					})
					.catch(() => {
						this.openSnackbar(this.$t('recover.txtErrorUpdate'));
					});
			}
		},
		clear() {
			this.$refs.form.reset();
		},
	},
};
</script>

<style scoped>
.fullh {
	height: 100%;
	background-color: #4527a0;
}
</style>
